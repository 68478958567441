import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from './src/components/Layout';
import GLBackdrop from './src/components/GLBackdrop';

export const wrapPageElement = ({element, props}) => {

        return (
          <React.Fragment>
            <GLBackdrop />
            <Layout>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    className="page-wrapper-inner"
                    key={props.location.pathname}
                    transition={{ duration: .5 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {element}
                  </motion.div>                  
                </AnimatePresence>
            </Layout>
          </React.Fragment>
        );
    }

export const onRouteUpdate = ({ location, prevLocation }) => {
  if(window.gl !== undefined) {
    window.gl.setLocation(location);
  }
}