import * as React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

const Footer = (props) => {
  const { menuIsOpen, closeMenuModal } = props;

  return (
    <footer id="site-footer" className={`footer ${menuIsOpen ? 'menu-open' : ''}`}>
      <div className="is-flex is-align-items-flex-end is-justify-content-space-between" >
        <div className="flex-left is-flex is-align-items-center">
          <div className="copyright">
            <span>® HOF {(new Date().getFullYear())} &nbsp;</span>
          </div>
          <StaticQuery
            query={graphql`
              query FooterQuery {
                markdownRemark(frontmatter: { filterKey: { eq: "footer-navigation" } }) {
                  frontmatter {
                    navitems {
                      navlink,
                      navtext
                    }
                  }
                }
              }`}
            render={data => {
              let items = data.markdownRemark.frontmatter.navitems.map(n => {
                return (
                  <li key={n.navLink}><Link className="footer-link" to={n.navlink} onClick={closeMenuModal()}>{n.navtext}</Link>
                  </li>)
              });
              return <ul className={"footer-link-list"}>
                {items}
              </ul>
            }}
          />
        </div>
        <div className="address">
          <span>½ Bond Street NYC</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
