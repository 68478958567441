import * as THREE from "three";
import * as gpu from "three/examples/jsm/misc/GPUComputationRenderer";
import { Noise } from 'noisejs';
import ShapeLoader from './ShapeLoader';
import gsap from 'gsap';
import { first } from "lodash";

// CONFIG VARS
const BACKGROUND_COLOR = 0xF0EBE0; // 0xF0EBE0 = beige
const FBO_SIZE = 128; // ~16k particles (128^2), should always equal a power of 2
const PARTICLE_SIZE = 7.; // size of each particle in pixels
const DEBUG_TEXTURES = false;
const PARTICLE_TEXTURE_URL = `/particles/texture-circle-sharp-sm.png`;
const NOISE_TEXTURE_URL = `/particles/texture-noise-xs.png`;

const BOUNDS = .5;
const BOUNDS_HALF = BOUNDS / 2;
const VEL_SCALE = .1;
const CAMERA_POS = 3.5;
const DEFAULT_TRANSITION_DURATION = 2.; // particle transition duration in seconds

const SHAPE_URL = `/particles/world-split-sphere.svg.bin`;


// Frag shader for particle visualizer
const frag = require('!!raw-loader!./glsl/particle-mat.frag').default;

// Vert shader for particle visuals
const vert = require('!!raw-loader!./glsl/particle-mat.vert').default;

// Off-screen shader for velocity updates
const velShader = require('!!raw-loader!./glsl/particle-vel.frag').default;

// Off-screen shader for position updates
const posShader = require('!!raw-loader!./glsl/particle-pos.frag').default;

let lastTime = 0.;
let lastFrameDuration = 1. / 60.;
let firstFrameTime = 0;
let t = 0.0;
let lastScroll = 0; 

class ThreeCanvas {
    constructor(el) {

        // first, load all the files we need, then start the simulation
        const shape = new ShapeLoader(SHAPE_URL)
            .then((shape) => {
                this.shape = shape;
                this.initialize(el);
            });
    }

    initialize(el) {


        // initialize variables and function bindings
        this.firstLoad = true;
        this.setLocation = this.setLocation.bind(this);
        this.lastLocation = null;
        this.lastSettings = [];
        this.burst = 0.0;
        this.BURST_SPEED = 1.0;
        this.BURST_VEL = .0125;
        
        const mousePos = new THREE.Vector3(-10, -10, 0);
        const targetPos = new THREE.Vector3(0, 0, 0);
        let frameCount = 0;

        // create a threejs renderer, scene, and camera
        const renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setPixelRatio(Math.min(1.5, window.devicePixelRatio));
        el.appendChild(renderer.domElement);

        const gl = renderer.getContext();
        gl.blendFunc(gl.GL_SRC_ALPHA, gl.GL_ONE_MINUS_SRC_ALPHA);     
        
        const scene = new THREE.Scene();
        
        const camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, .1, 100);
        camera.position.z = CAMERA_POS;

        // initialize compute renderers (for particle sim calculations)
        const gpuCompute = new gpu.GPUComputationRenderer(FBO_SIZE, FBO_SIZE, renderer);
        const pos0 = gpuCompute.createTexture();
        const vel0 = gpuCompute.createTexture();

        // fillPositionTexture(pos0);
        fillVelocityTexture(vel0);

        let a = new Float32Array(FBO_SIZE*FBO_SIZE*4);

        // make a static copy of this texture as well
        const startPos = new THREE.DataTexture(a, 128, 128, THREE.RGBAFormat, THREE.FloatType);
        startPos.needsUpdate = true;

        // map texture to a sphere
        for(let i = 0; i < FBO_SIZE**2; i++) {

            const x = (this.shape[i*3]/65536) - .5;
            const y = (1. - (this.shape[i*3+1]/65536)) - .5;
            const z = (this.shape[i*3+2]/65536) - .5;

            const theArray = pos0.image.data;
            theArray[ i*4 + 0 ] = x;
            theArray[ i*4 + 1 ] = y;
            theArray[ i*4 + 2 ] = z;
            theArray[ i*4 + 3 ] = 0.;
            
            startPos.image.data[i*4] = x;
            startPos.image.data[i*4+1] = y;
            startPos.image.data[i*4+2] = z;
        }
        startPos.needsUpdate = true;


        function fillPositionTexture(texture) {
            const RADIUS = .3;
            const theArray = texture.image.data;
            let v = new THREE.Vector3(1., 0, 0);
            let axis = new THREE.Vector3(0, 0, 1);
            // let noise = new Noise(Math.random());
            for (let k = 0, kl = theArray.length; k < kl; k += 4) {

                // // random position in a cube
                const x = (Math.random() * BOUNDS) - BOUNDS_HALF;
                const y = (Math.random() * BOUNDS) - BOUNDS_HALF;
                const z = (Math.random() * BOUNDS) - BOUNDS_HALF;

                // theArray[ k + 0 ] = x;
                // theArray[ k + 1 ] = Math.pow(y,4)*.25;
                // if(y < 0) theArray[ k + 1 ] *= -1;
                // theArray[ k + 2 ] = z*.25;
                // const SPLAT_SCALE = 2.;
                // // random position on a ring
                // var angle = (Math.PI * 2.0) * Math.random();
                // v.set(RADIUS, 0., 0.);
                // v.applyAxisAngle(axis, angle);
                // let pn = noise.simplex2(v.x * SPLAT_SCALE, v.y * SPLAT_SCALE);
                // v.multiplyScalar(1. + pn * .5);
                // v.multiplyScalar(Math.pow(Math.random(), 2.));
                // // v.multiplyScalar(5.);
                // const x = v.x;//(Math.random() * BOUNDS) - BOUNDS_HALF;
                // const y = v.y;//(Math.random() * BOUNDS) - BOUNDS_HALF;
                // const z = (Math.random() * BOUNDS) - BOUNDS_HALF;

                // theArray[k + 0] = x;
                // theArray[k + 1] = y;//Math.pow(y,4)*.25;
                // // if(y < 0) theArray[ k + 1 ] *= -1;
                // theArray[k + 2] = Math.random() - .5;

                // // random position on a sphere
                // v.randomDirection();
                // v.multiplyScalar(RADIUS);
                // const x = v.x;//(Math.random() * BOUNDS) - BOUNDS_HALF;
                // const y = v.y;//(Math.random() * BOUNDS) - BOUNDS_HALF;
                // const z = 0.;//(Math.random() * BOUNDS) - BOUNDS_HALF;

                // theArray[ k + 0 ] = x;
                // theArray[ k + 1 ] = y;
                // theArray[ k + 2 ] = z;

                theArray[k + 3] = 0.;//Math.random();
            }

        }

        function fillVelocityTexture(texture) {

            const theArray = texture.image.data;
            const maxvel = .0;
            // generate random locations on the face of a sphere
            let v = new THREE.Vector3(0, 0, 0);

            for (let k = 0, kl = theArray.length; k < kl; k += 4) {

                // // random position in a cube
                // v.randomDirection().multiplyScalar(VEL_SCALE+Math.random()*.01);

                // random position on a square
                v.x = (Math.random() - .5) * maxvel;
                v.y = (Math.random() - .5) * maxvel;
                v.z = (Math.random() - .5) * maxvel;

                // random position on face of sphere


                theArray[k + 0] = v.x;
                theArray[k + 1] = v.y;
                theArray[k + 2] = v.z;
                theArray[k + 3] = 1;

            }

        }

        const velVar = gpuCompute.addVariable("textureVelocity", velShader, vel0);
        const posVar = gpuCompute.addVariable("texturePosition", posShader, pos0);

        this.velVar = velVar;
        this.posVar = posVar;

        gpuCompute.setVariableDependencies(velVar, [velVar, posVar]);
        gpuCompute.setVariableDependencies(posVar, [velVar, posVar]);

        var error = gpuCompute.init();
        if (error !== null) {
            console.error(error);
        }

        // Create geometry for our particles
        const geo = new THREE.PlaneGeometry(1., 1., FBO_SIZE, FBO_SIZE);

        // Load textures
        const noiseTex = new THREE.TextureLoader().load(NOISE_TEXTURE_URL, (t) => {
            t.wrapS = THREE.RepeatWrapping;
            t.wrapT = THREE.RepeatWrapping;
            start();
        });

        const particleTex = new THREE.TextureLoader().load(PARTICLE_TEXTURE_URL);

        // setup uniforms for vel/pos compute shaders
        velVar.material.uniforms.time = { value: 0.0 };
        velVar.material.uniforms.lastFrameDuration = { value: lastFrameDuration };
        velVar.material.uniforms.startPos = { value: startPos };
        velVar.material.uniforms.burst = { value: 0.0 };
        velVar.material.uniforms.noiseTex = { value: noiseTex };
        velVar.material.uniforms.gravForce = { value: 0. };        
        posVar.material.uniforms.time = { value: 0.0 };
        posVar.material.uniforms.noiseTex = { value: noiseTex };
        posVar.material.uniforms.burst = { value: 0.0 };
        posVar.material.uniforms.rotation = { value: 0.0 };
        velVar.material.uniforms.targetPos = { value: targetPos };
        velVar.material.uniforms.mousePos = { value: mousePos };
        velVar.material.uniforms.startPos = { value: startPos };
        velVar.material.uniforms.rotation = { value: 0.0 };
        // define a custom shader material for our particles
        const mat = new THREE.ShaderMaterial({
            uniforms: {
                time: { value: 1.0 },
                resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
                fboTex: { value: noiseTex },
                velTex: { value: noiseTex },
                particleTex: { value: particleTex },
                noiseTex: { value: noiseTex },
                fade: { value: 0.0 },
                burst: { value: 0.0 },
                lastFrameDuration: { value: lastFrameDuration },
                col1: { value: new THREE.Vector4(1., 1., 1., 1.) },
                col2: { value: new THREE.Vector4(1., .96, .94, 1.) },
                particleSize: { value: PARTICLE_SIZE },
            },
            vertexShader: vert,
            fragmentShader: frag,
            depthTest: false,
            transparent: true,
        });
        this.mat = mat;


        // create a pointcloud mesh from our plane geometry
        // and our shader material
        const mesh = new THREE.Points(geo, mat);
        mesh.position.z = 0.;
        mesh.position.x = 0.;
        mesh.position.y = 0.;
        scene.add(mesh);

        const raycastMesh = new THREE.Mesh(new THREE.SphereGeometry(.5), new THREE.MeshBasicMaterial({ color: new THREE.Color(0xff0000) }));
        raycastMesh.visible = false;
        scene.add(raycastMesh);

        // optional DEBUG textures to show pos/vel data
        if (DEBUG_TEXTURES) {
            this.debugMesh = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: .25 }));
            this.debugMesh.position.z = 0;
            scene.add(this.debugMesh);
        }


        // particle settings for specific page routes. defaults
        // to homepage settings if none are defined
        this.particleSettings = {
            '/': [
                {
                    target: this.mat.uniforms.col1.value,
                    value: {
                        x: .82,
                        y: .84,
                        z: .85,
                        w: .05
                    },
                },
                {
                    target: this.mat.uniforms.col2.value,
                    value: {
                        x: 1.05,
                        y: 1.,
                        z: .9,
                        w: .15
                    },
                },
                {
                    target: this.mat.uniforms.particleSize,
                    value: { value: PARTICLE_SIZE * .75 },
                },
                {
                    target: this.velVar.material.uniforms.gravForce,
                    value: { value: .005 },
                },                       
            ],
            '/portfolio': [
                {
                    target: this.mat.uniforms.col1.value,
                    value: {
                        x: .82,
                        y: .84,
                        z: .85,
                        w: .01
                    },
                },
                {
                    target: this.mat.uniforms.col2.value,
                    value: {
                        x: 1.,
                        y: 1.,
                        z: .9,
                        w: .05
                    },
                },
                {
                    target: this.mat.uniforms.particleSize,
                    value: { value: PARTICLE_SIZE * .5 },
                },
                {
                    target: this.velVar.material.uniforms.gravForce,
                    value: { value: .005 },
                },                       
            ],
            '/partners': [
                {
                    target: this.mat.uniforms.col1.value,
                    value: {
                        x: .82,
                        y: .84,
                        z: .85,
                        w: .01
                    },
                },
                {
                    target: this.mat.uniforms.col2.value,
                    value: {
                        x: 1.,
                        y: 1.,
                        z: .9,
                        w: .05
                    },
                },
                {
                    target: this.mat.uniforms.particleSize,
                    value: { value: PARTICLE_SIZE * .5 },
                },
                {
                    target: this.velVar.material.uniforms.gravForce,
                    value: { value: .005 },
                },                       
            ],
            '/tomorrow-talk': [
                {
                    target: this.mat.uniforms.col1.value,
                    value: {
                        x: .8,  // red
                        y: .8,  // green
                        z: 1.,  // blue
                        w: .05, // alpha
                    },
                },
                {
                    target: this.mat.uniforms.col2.value,
                    value: {
                        x: .9, // red
                        y: .8,  // green
                        z: 1.,  // blue
                        w: .2,  // alpha
                    },
                },
                {
                    target: this.mat.uniforms.particleSize,
                    value: { value: PARTICLE_SIZE * .5 },
                },
                {
                    target: this.velVar.material.uniforms.gravForce,
                    value: { value: .005 },
                },                
            ],
            '/thesis': [
                {
                    target: this.mat.uniforms.col1.value,
                    value: {
                        x: .8,  // red
                        y: .8,  // green
                        z: 1.,  // blue
                        w: .05, // alpha
                    },
                },
                {
                    target: this.mat.uniforms.col2.value,
                    value: {
                        x: .9, // red
                        y: .6,  // green
                        z: .8,  // blue
                        w: .2,  // alpha
                    },
                },
                {
                    target: this.mat.uniforms.particleSize,
                    value: { value: PARTICLE_SIZE * .5 },
                },
                {
                    target: this.velVar.material.uniforms.gravForce,
                    value: { value: .005 },
                },                 
            ],
        }

        this.setLocation({ pathname: this.currentLocation });

        // frame loop
        function loop(time) {

            if(!firstFrameTime) {
                firstFrameTime = time;
            }

            // handle motion burst that happens on page nav
            if (window.gl.burst > 0) {
                window.gl.burst -= (window.gl.burst * window.gl.BURST_VEL);
                window.gl.burst = Math.max(0., window.gl.burst);
            }

            // map page scroll to rotation
            mesh.position.z = -window.scrollY * .00005;

            // calculate the time between frames to keep physics
            // "the same" despite different frame rates.
            lastFrameDuration = time - lastTime;
            lastTime = time;
            t += lastFrameDuration;

            // generic loop to call .update() on any scene objects
            // that have it defined
            scene.traverse(o => {
                if (o.update !== undefined) {
                    o.update(t);
                }
            })

            // update uniforms
            mat.uniforms.time.value = t;
            mat.uniforms.burst.value = window.gl.burst;
            mat.uniforms.fade.value = (t-firstFrameTime)*.0005;
            velVar.material.uniforms.time.value = t;
            velVar.material.uniforms.targetPos.value.copy(targetPos);
            velVar.material.uniforms.mousePos.value.copy(mousePos);
            velVar.material.uniforms.rotation.value += .05;
            velVar.material.uniforms.burst.value = window.gl.burst;
            posVar.material.uniforms.time.value = t;
            posVar.material.uniforms.burst.value = window.gl.burst;
            posVar.material.uniforms.rotation.value += .05;// + scrollDelta*.015;
            gpuCompute.compute();

            // update ping pong buffer (position + velocity calulations)
            let current = frameCount & 1 ? 1 : 0;
            let last = frameCount & 1 ? 0 : 1;
            mat.uniforms.fboTex.value = gpuCompute.getCurrentRenderTarget(posVar).texture;
            mat.uniforms.velTex.value = gpuCompute.getCurrentRenderTarget(velVar).texture;
            mat.uniforms.lastFrameDuration.value = lastFrameDuration;
            velVar.material.uniforms.lastFrameDuration.value = lastFrameDuration;
            if(DEBUG_TEXTURES) {
                this.debugMesh.material.map = gpuCompute.getCurrentRenderTarget(velVar).texture;
            }
            
            // finally render the actual scene
            renderer.setRenderTarget(null);
            renderer.clear();
            renderer.render(scene, camera);

            ++frameCount;

            // do it all again next frame
            requestAnimationFrame(loop);
        }

        // kickstart the frameloop
        function start() {
            // initialize FBO
            window.requestAnimationFrame(loop);
        }

        // calculates the height of the screen in world units
        // at a certain distance from the camera
        const visibleHeightAtZDepth = (depth, camera) => {
            // compensate for cameras not positioned at z=0
            const cameraOffset = camera.position.z;
            if (depth < cameraOffset) depth -= cameraOffset;
            else depth += cameraOffset;

            // vertical fov in radians
            const vFOV = camera.fov * Math.PI / 180;

            // Math.abs to ensure the result is always positive
            return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
        };

        // calculates the width of the screen in world units
        // at a certain distance from the camera
        const visibleWidthAtZDepth = (depth, camera) => {
            const height = visibleHeightAtZDepth(depth, camera);
            return height * camera.aspect;
        };

        const screenBounds = new THREE.Vector2(0, 0);
        
        // converts the mouse position to a world position, along with the button press
        function handleMouseMove(e) {
            mousePos.x = screenBounds.x * ((e.clientX / window.innerWidth) - .5) * 2.;
            mousePos.y = screenBounds.y * -((e.clientY / window.innerHeight) - .5) * 2.;
            mousePos.z = e.buttons ? 1.0 : 0.0;

            // normalized screen location
        }

        window.addEventListener('mousemove', handleMouseMove);
 
        // resize handler
        function handleResize(e) {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            screenBounds.x = visibleWidthAtZDepth(camera.position.z / 2, camera);
            screenBounds.y = visibleHeightAtZDepth(camera.position.z / 2, camera);
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.setPixelRatio(Math.min(1.5, window.devicePixelRatio));
        }

        window.addEventListener('resize', handleResize, false);
        handleResize(); // initial scale

        gsap.defaults({
            duration: DEFAULT_TRANSITION_DURATION,
            ease: "power1.inout",
        })

    }

    // page transition handler
    setLocation(location) {
        let p = location.pathname;
        this.currentLocation = p;
        if(p === null) return;
        
        // strip trailing slashes out of the pathname
        p = p.replace(/\/+$/, '');

        // skip events if it's where we already are
        if (p === this.lastLocation) {
            return; 
        }

        // update lastLocation
        this.lastLocation = p;

        if(this.particleSettings === undefined) return;
        // look up particle settings for this path
        let data = this.particleSettings[p];

        // if none are available, use the default
        if (data === undefined) {
            data = this.particleSettings['/'];
        }

        // if it's our first page load, duration should be zero
        if (this.firstLoad) {
            this.firstLoad = false;
            
            if (this.mat.uniforms !== undefined) {
                this.burst = this.BURST_SPEED;
                data.map(d => gsap.set(d.target, d.value))
            }
        } else {
            // trigger a burst of motion
            this.burst = this.BURST_SPEED;

            // lerp all particle settings based on definitions
            data.map(d => {
                d.value.duration = DEFAULT_TRANSITION_DURATION;
                gsap.to(d.target, d.value)
            })
        }

    }

}


export default ThreeCanvas;