import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import logo from "../img/HOF.svg";
import logoWhite from "../img/HOF-white.svg";
import ReactModal from "react-modal";

const customStyles = { // Styles for modal menu
  overlay: {
    zIndex: 3,
    backgroundColor: '#2D2D2D',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    borderRadius: '0',
    border: 'none',
  },
};


if(typeof window !== "undefined"){
  ReactModal.setAppElement('#___gatsby');
}
const Navbar = (props) => {
  //console.log(props);
  const { menuIsOpen, openMenuModal, closeMenuModal } = props;
  
    return (
      <header id="site-header" className={menuIsOpen ? 'menu-open': ''}>
      <nav
        className="columns navbar"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="is-flex is-align-content-stretch column is-full">
          <div
            id="navMenu"
            className="navbar-menu is-justify-content-space-between is-align-items-fex-start"
          >
            <div className="navbar-start has-text-centered">
              <div className="is-flex is-align-items-center navbar-logo">
                <Link to="/" onClick={closeMenuModal()}><img src={menuIsOpen ? logoWhite : logo} alt="HOF Capital logo"/></Link>
              </div>
            </div>
            <div className="navbar-end is-flex is-align-items-center has-text-centered">
              <a className="navbar-item is-tab is-flex is-align-items-center" href="https://hofcapital.investorvision.intralinks.com/" target="_blank"><span>Login</span></a>
              <a className="navbar-item navbar-hamburger is-tab is-flex is-align-items-center is-justify-content-center" href="#" onClick={menuIsOpen ? closeMenuModal() : openMenuModal()}><div className="navbar-hamburger-icon"></div></a>
            </div>
          </div>
        </div>
      </nav>
        <ReactModal
          isOpen={menuIsOpen}
          onRequestClose={closeMenuModal()}
          contentLabel="Nav Modal"
          style={customStyles}
        >

          <div id="modal-menu-container">
            <ul id="modal-menu">

            <StaticQuery
                query={graphql`
                query NavQuery {
                  markdownRemark(frontmatter: { filterKey: { eq: "modal-nav" } }) {
                    frontmatter {
                      navitems {
                        navlink,
                        navtext
                      }
                    }
                  }
                }`}
                render={data => {
                  let navItems = data.markdownRemark.frontmatter.navitems.map(n => (
                    <li className="modal-menu-item" key={n.navlink}>
                      <Link to={'/'+n.navlink} onClick={closeMenuModal()}>{n.navtext}</Link>
                    </li>
                  ));
                  return (
                    <span>
                      {navItems}
                    </span>
                  )
                }}
              />
            </ul>
          </div>
        </ReactModal>
      </header>
    );
};

export default Navbar;
