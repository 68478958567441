import React, {useState} from "react";
import { Helmet } from "react-helmet";
import "../scss/bootstrap.css";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../scss/style.scss";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix, Link } from "gatsby";
import {motion} from "framer-motion";

const TemplateWrapper = (props) => {
  const { animate } = props
  const { children } = props
  const { title, description } = useSiteMetadata();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);

  const openMenuModal = () => {
    setModalIsOpen(true);
    setScrollOffset(window.scrollY);
    document.body.setAttribute('style', `position: fixed; top: ${scrollOffset}px; left: 0; right: 0;`);
  }
  const closeMenuModal = () => {
    setModalIsOpen(false);
    document.body.setAttribute('style', ``);
    window.scrollTo(0, scrollOffset);
  }

  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`../img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`../img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`../img/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}static/img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <link
          rel="manifest"
          href={`${withPrefix("/")}static/img/site.webmanifest`}
        />
        <link
          type="text/javascript"
          src={`${withPrefix("/")}static/js/main.js`}
        />
        <meta name="theme-color" content="#fff" />
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.png`}
        />
        
      </Helmet>
      <Navbar openMenuModal={()=>openMenuModal} closeMenuModal={()=>closeMenuModal} menuIsOpen={modalIsOpen}/>
      {animate === false ? (<main className="page-wrapper"> {children} </main>) : ( <motion.main
          className="page-wrapper"
          initial={{ opacity: 0}}
          animate={{ opacity: 1}}
          exit={{ opacity: 0}}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.4
          }}> {children} </motion.main>) }
          <Link to="/tomorrow-talk" className="tt-sticky-link desktop-hidden mobile-hidden">Tomorrow Talk</Link>
      <Footer menuIsOpen={modalIsOpen} closeMenuModal={()=>closeMenuModal} />
    </React.Fragment>
  );
};

export default TemplateWrapper;
