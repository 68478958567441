import * as React from "react";
import ThreeCanvas from './ThreeCanvas';

const GLBackdrop = class extends React.Component {

  constructor(props) {
    super(props);
    this.glContainer = React.createRef();
  }

  componentDidMount() {
    // only run in browser
    if (typeof window !== `undefined`) {

      // skip if reduced motion is preferred
      const mediaQuery = '(prefers-reduced-motion: no-preference)';
      const withMotion = Boolean(window.matchMedia(mediaQuery).matches);

      if(withMotion) {
        this.gl = new ThreeCanvas(this.glContainer.current);
        window.gl = this.gl;
      }

    }
  }

  // never update/re-render
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
        <div className="GLBackdrop">
            <div className="GLContainer" ref={this.glContainer} />
        </div>
    );
  }
};

export default GLBackdrop;
